import React, {MutableRefObject, useCallback, useEffect, useRef, useState} from 'react';
import st from './min-max-slider.module.scss';
import SlideLine from "./slide-line/slide-line";
import {EMinMaxUnit, TMinMaxValue} from "../../../types/minMaxSlider";
import {c} from "../../../utils/classnames.utils";
import {formatNumber} from "../../../utils/strings";
import EdgeSwiper from "./edge-swiper/edge-swiper";
import { styled, alpha, padding } from '@mui/system';
import { Slider as BaseSlider, sliderClasses } from '@mui/base/Slider';

interface IMinMaxSliderProps {
    values: TMinMaxValue,
    setValues: (values: TMinMaxValue) => void;
    unit: EMinMaxUnit;
    formatNumbers: boolean;
}

const blue = {
    100: '#DAECFF',
    200: '#99CCF3',
    400: '#3399FF',
    300: '#66B2FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B3',
    900: '#003A75',
  };

  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };

const Slider = styled(BaseSlider)(
    ({ theme }) => `
    color: #FA1515;
    height: 4px;
    width: 100%;
    padding: 16px 0;
    display: inline-flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    touch-action: none;
    -webkit-tap-highlight-color: transparent;

    & .${sliderClasses.rail} {
      display: block;
      position: absolute;
      width: 100%;
      height: 4px;
      border-radius: 6px;
      background-color: #d9d9d9;
      opacity: 0.3;
    }

    & .${sliderClasses.track} {
      display: block;
      position: absolute;
      height: 4px;
      border-radius: 6px;
      background-color: currentColor;
    }

    & .${sliderClasses.thumb} {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        margin-left: -6px;
        width: 20px;
        height: 20px;
        box-sizing: border-box;
        border-radius: 50%;
        outline: 0;
        background-color: #fff;
        transition-property: box-shadow, transform;
        transition-timing-function: ease;
        transition-duration: 120ms;
        transform-origin: center;
        box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.12);
    }
  `,
  );

const MinMaxSlider = ({values, setValues, unit, formatNumbers}: IMinMaxSliderProps) => {
    const minValueSpanRef = useRef<HTMLSpanElement>(null);
    const maxValueSpanRef = useRef<HTMLSpanElement>(null);
    const [isLeftHandlerMoving, setIsLeftHandlerMoving] = useState(false);
    const [isRightHandlerMoving, setIsRightHandlerMoving] = useState(false);

    const handleBlurMinValue = (event: React.FocusEvent<HTMLSpanElement>) => {
        let newValues = {...values};
        try {
            const newMinValue = Number(event.target.innerText.replaceAll(' ', ''));
            if (newMinValue <= values.maxValue.default && newMinValue >= values.minValue.default) {
                newValues = {...newValues, minValue: {...newValues.minValue, currency: newMinValue}};
            }
        } catch (e) {
            console.error(e);
        } finally {
            setValues(newValues);
        }
    }

    const handleBlurMaxValue = (event: React.FocusEvent<HTMLSpanElement>) => {
        let newValues = {...values};
        try {
            const newMaxValue = Number(event.target.innerText.replaceAll(' ', ''));
            if (newMaxValue <= values.maxValue.default && newMaxValue >= values.minValue.default) {
                newValues = {...newValues, maxValue: {...newValues.maxValue, currency: newMaxValue}};
            }
        } catch (e) {
            console.error(e);
        } finally {
            setValues(newValues);
        }
    }

    useEffect(() => {
        if (!isLeftHandlerMoving && !isRightHandlerMoving) {
            (minValueSpanRef as MutableRefObject<HTMLSpanElement>).current.innerText = formatNumber(values.minValue.currency, formatNumbers);
            (maxValueSpanRef as MutableRefObject<HTMLSpanElement>).current.innerText = formatNumber(values.maxValue.currency, formatNumbers);
        }
    }, [values, isLeftHandlerMoving, isRightHandlerMoving]);

    const valuesRef = useRef(values);

    useEffect(() => {
        valuesRef.current = values;

    }, [values])

    const leftEdgeSwipe = () => {
        const newValues = {
            minValue: {
                default: valuesRef.current.minValue.default,
                currency: valuesRef.current.minValue.default,
            },
            maxValue: {
                default: valuesRef.current.maxValue.default,
                currency: valuesRef.current.maxValue.currency
            }
        }
        setValues(newValues);
    }

    const rightEdgeSwipe = () => {
        const newValues = {
            minValue: {
                default: valuesRef.current.minValue.default,
                currency: valuesRef.current.minValue.currency,
            },
            maxValue: {
                default: valuesRef.current.maxValue.default,
                currency: valuesRef.current.maxValue.default
            }
        }
        setValues(newValues);
    }

    const handleChange = (event: Event, newValue: any) => {
        setValues({minValue: {...values.minValue, currency: newValue[0]}, maxValue: {...values.maxValue, currency: newValue[1]}});
      };

    return (
        <div className={st.main}>
            <div className={st.values__container}>
                <div className={c(st.value__container, st.left_side)}>
                    <span className={c(st.value__text, st.pretext)}>{`от`}</span>
                    <span
                        ref={minValueSpanRef}
                        contentEditable={true}
                        inputMode="numeric"
                        suppressContentEditableWarning={true}
                        onBlur={event => handleBlurMinValue(event)}
                        className={st.value__text}>{formatNumber(values.minValue.currency, formatNumbers)}</span>
                    <span
                        className={c(st.value__text, st.left_space)}>{unit}</span>
                </div>
                <div className={c(st.value__container, st.right_side)}>
                    <span className={c(st.value__text, st.pretext)}>{`до`}</span>
                    <span
                        ref={maxValueSpanRef}
                        contentEditable={true}
                        inputMode="numeric"
                        suppressContentEditableWarning={true}
                        onBlur={event => handleBlurMaxValue(event)}
                        className={st.value__text}>{formatNumber(values.maxValue.currency, formatNumbers)}</span>
                    <span
                        className={c(st.value__text, st.left_space)}>{unit}</span>
                </div>
            </div>
            <div style={{padding: '0 8px'}}>
            <Slider
                value={[values.minValue.currency, values.maxValue.currency]}
                min={values.minValue.default}
                max={values.maxValue.default}
                onChange={handleChange}
                valueLabelDisplay="auto"
            />
            </div>
            {/* <SlideLine values={values} setValues={setValues} isLeftHandlerMoving={isLeftHandlerMoving}
                       setIsLeftHandlerMoving={setIsLeftHandlerMoving} isRightHandlerMoving={isRightHandlerMoving}
                       setIsRightHandlerMoving={setIsRightHandlerMoving}/> */}
            <div className={c(st.edge_swiper__container, st.left)}>
                <EdgeSwiper direction={'left'} onSwipe={leftEdgeSwipe}/>
            </div>
            <div className={c(st.edge_swiper__container, st.right)}>
                <EdgeSwiper direction={'right'} onSwipe={rightEdgeSwipe}/>
            </div>
        </div>
    );
};

export default MinMaxSlider;
