import React, {useEffect, useRef} from 'react';
import st from './edge-swiper.module.scss';

interface IEdgeSwiperProps {
    direction: 'left' | 'right';
    onSwipe: () => void;
}

const EdgeSwiper = ({direction, onSwipe}: IEdgeSwiperProps) => {
    const startX = useRef<number | null>(null);
    const startY = useRef<number | null>(null);
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleTouchStart = (e: TouchEvent) => {
            const touch = e.touches[0];
            startX.current = touch.clientX;
            startY.current = touch.clientY;
        };

        const handleTouchMove = (e: TouchEvent) => {
            if (startX.current === null || startY.current === null) return;

            const touch = e.touches[0];
            const deltaX = touch.clientX - startX.current;
            const deltaY = touch.clientY - startY.current;

            const rect = containerRef.current?.getBoundingClientRect();
            if (rect && (
                touch.clientX >= rect.left && touch.clientX <= rect.right &&
                touch.clientY >= rect.top && touch.clientY <= rect.bottom
            )) {
                if (Math.abs(deltaX) > Math.abs(deltaY)) {
                    if (deltaX > 0) {
                        if (direction === 'right') {
                            onSwipe();
                        }
                    } else {
                        if (direction === 'left') {
                            onSwipe();
                        }
                    }
                }
            }
        };

        const handleTouchEnd = () => {
            startX.current = null;
            startY.current = null;
        };

        document.addEventListener('touchstart', handleTouchStart);
        document.addEventListener('touchmove', handleTouchMove);
        document.addEventListener('touchend', handleTouchEnd);

        return () => {
            document.removeEventListener('touchstart', handleTouchStart);
            document.removeEventListener('touchmove', handleTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, []);

    return (
        <div className={st.main} ref={containerRef}/>
    );
};

export default EdgeSwiper;