import React, {useEffect, useState} from 'react';
import AllObjectsPage from "./pages/all-objects-page/all-objects-page";
import {AppContext} from "./context/context";
import {TApartment, TProject} from "./types/project";
import ProjectAPI from "./api/project";
import Loader from "./components/ui/loader/loader";
import ApartmentPage from "./pages/apartment-page/apartment-page";
import AgreementWindow from "./components/agreement-window/agreement-window";
import HeaderLayout from "./components/header-layout/header-layout";
import useTelegram from './hooks/useTelegram';
import { getDefaultOption, IReadyTermOption } from './types/readyTermOptions';
import { getMinMaxValue, TMinMaxValue } from './types/minMaxSlider';

function App() {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [projects, setProjects] = useState<TProject[]>([]);
    const [selectedProjectIndex, setSelectedProjectIndex] = useState<number>(0);
    const [selectedApartment, setSelectedApartment] = useState<TApartment | null>(null);
    const [apartmentList, setApartmentList] = useState<TApartment[]>([]);
    const [buildingList, setBuildingList] = useState<any>([]);
    const [userHasAgreed, setUserHasAgreed] = useState<boolean>(false);
    const [floorList, setFloorList] = useState<any>([]);
    const [user, setUser] = useState<any>(null);
    const [phone, setPhone] = useState<string>('');

    const [maxRoomsCount, setMaxRoomsCount] = React.useState<number>(0);
    const [readyTermOptions, setReadyTermOptions] = React.useState<IReadyTermOption[]>([])
    const [selectedRoomCountOptions, setSelectedRoomCountOptions] = React.useState<string[]>([])
    const [minMaxPriceValues, setMinMaxPriceValues] = React.useState<TMinMaxValue>(getMinMaxValue(0, 0))
    const [readyTermSelectedOption, setReadyTermSelectedOption] = React.useState<IReadyTermOption>(getDefaultOption())
    const [minMaxAreaValues, setMinMaxAreaValues] = React.useState<TMinMaxValue>(getMinMaxValue(0, 0))

    const { getUserInfo, telegram } = useTelegram()

    const fetchProjects = async () => {
        try {
            const response = await ProjectAPI.getProjectList();
            setProjects(response);
            setIsLoading(false);
        } catch (error) {
            console.error('Something went wrong: ', error);
        }
    };

    useEffect(() => {
        fetchProjects();
        setUser(getUserInfo());
        // @ts-ignore
        if(telegram) {
            const phone = localStorage.getItem('phone');
            if(phone) {
                // @ts-ignore
                setPhone(phone)
            } else {
                telegram.requestContact((isConfirm: any, data: any) => {
                    if(isConfirm) {
                        setPhone(data.responseUnsafe.contact.phone_number)
                        localStorage.setItem('phone', data.responseUnsafe.contact.phone_number)
                    }
                });
            }
        }
    }, []);

    // scrolls to up when user chooses another apartment
    useEffect(() => {
        // console.log(selectedApartment)
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [selectedApartment]);



    return (
        <AppContext.Provider value={{
            isLoading,
            setIsLoading,
            projects,
            setProjects,
            selectedProjectIndex,
            setSelectedProjectIndex,
            selectedApartment,
            setSelectedApartment,
            apartmentList,
            setApartmentList,
            buildingList,
            setBuildingList,
            floorList,
            setFloorList,
            user,
            setUser,
            setMaxRoomsCount,
            maxRoomsCount,
            setReadyTermOptions,
            readyTermOptions,
            readyTermSelectedOption,
            setReadyTermSelectedOption,
            minMaxPriceValues,
            setMinMaxPriceValues,
            minMaxAreaValues,
            setMinMaxAreaValues,
            selectedRoomCountOptions,
            setSelectedRoomCountOptions,
            phone,
            setPhone
        }}>
            {
                userHasAgreed ?
                        <>
                            {
                                selectedApartment ?
                                    <ApartmentPage/> :
                                    <AllObjectsPage/>
                            }
                            <Loader isActive={isLoading}/>
                        </> :
                    <AgreementWindow setUserHasAgreed={setUserHasAgreed}/>
            }
        </AppContext.Provider>
    );
}


export default App;
