import {TApartment} from "../../types/project";

export const mappingRawToApartment = (rawData: any[]):TApartment[] => {
    const result:TApartment[] = []
    rawData.forEach((rawItem) => {
        result.push({
            id: rawItem.guid,
            address: '',
            buildingBody: '',
            number: rawItem.number,
            projectName: 'Равновесие',
            planImage: rawItem.plan,
            metroName: '',
            metroWalkTime: '',
            section: rawItem.section_number,
            rooms: rawItem.room_count,
            square: rawItem.square,
            finishing: rawItem.square ? 'С отделкой' : 'White box',
            floor: rawItem.floor_number,
            typicalFinishingType: rawItem.typicalFinishingType,
            buildingId: rawItem.building_id,
            finishPlan: {
                quarter: rawItem.readyQuarter,
                year: rawItem.readyYear
            },
            price: {
                actually: rawItem.discount,
                before: rawItem.total_cost
            }
        })
    })
    return result;
}
