export interface IReadyTermOption {
    title: string;
    queryValue: string;
    quarter: number;
    year: number;
}

export const getDefaultOption = () => {
    return {
        title: 'Любой',
        queryValue: 'Любой',
        quarter: 0,
        year: 0,
    }
}