import React, {useMemo} from 'react';
import st from './filter-bar.module.scss';
import Button from "../ui/button/button";
import {c} from "../../utils/classnames.utils";
import RoomCountSelector from "../ui/drop-down-components/room-count-selector";
import MinMaxSlider from "../ui/min-max-slider/min-max-slider";
import {EMinMaxUnit, TMinMaxValue} from "../../types/minMaxSlider";
import DropDownList from "../ui/drop-down-components/drop-down-list";
import {IReadyTermOption} from "../../types/readyTermOptions";

interface IFilterBarProps {
    selectedRoomCountOptions: string[];
    minMaxPriceValues: TMinMaxValue;
    dropDownSelectedOption: IReadyTermOption;
    minMaxAreaValues: TMinMaxValue;
    setSelectedRoomCountOptions: (value: string[]) => void;
    setMinMaxPriceValues: (value: TMinMaxValue) => void;
    setDropDownSelectedOption: (value: IReadyTermOption) => void;
    setMinMaxAreaValues: (value: TMinMaxValue) => void;
    dropDownOptionsList: IReadyTermOption[];
    maxRoomsCount: number;
    isClickable: boolean;
}

const FilterBar = ({
                       selectedRoomCountOptions,
                       minMaxPriceValues,
                       dropDownSelectedOption,
                       minMaxAreaValues,
                       setSelectedRoomCountOptions,
                       setMinMaxPriceValues,
                       setDropDownSelectedOption,
                       setMinMaxAreaValues,
                       dropDownOptionsList,
                       maxRoomsCount,
                       isClickable,
                   }: IFilterBarProps) => {
    const [filtersAreOpen, setFiltersAreOpen] = React.useState(false);

    const buttonText = useMemo(() => {
        return filtersAreOpen ? 'Свернуть фильтры' : 'Развернуть фильтры';
    }, [filtersAreOpen])

    const setDropDown = (option: string) => {
        const selectedOption = dropDownOptionsList.find(opt => opt.title === option);
        if (selectedOption) {
            setDropDownSelectedOption(selectedOption);
        }
    }

    const onOpenFiltersButtonClick = () => {
        console.log(1, isClickable)
        if (isClickable) {
            setFiltersAreOpen(prevState => !prevState)
        }
    }

    return (
        <div className={st.main}>
            <div className={c(st.filters__container, {[st.open]: filtersAreOpen})}>
                <RoomCountSelector maxRoomCount={maxRoomsCount} selectedOptions={selectedRoomCountOptions}
                                   setSelectedOptions={setSelectedRoomCountOptions}/>
                <div className={st.filter_item__container}>
                    <span className="label">СТОИМОСТЬ</span>
                    <MinMaxSlider values={minMaxPriceValues} setValues={setMinMaxPriceValues} unit={EMinMaxUnit.PRICE} formatNumbers={true}/>
                </div>
                <div className={st.filter_item__container}>
                    <span className="label">СРОК СДАЧИ</span>
                    <DropDownList selectedOption={dropDownSelectedOption.title} setSelectedOption={setDropDown}
                                  optionList={dropDownOptionsList.map(i => i.title)}/>
                </div>
                <div className={st.filter_item__container}>
                    <span className="label">ПЛОЩАДЬ</span>
                    <MinMaxSlider values={minMaxAreaValues} setValues={setMinMaxAreaValues} unit={EMinMaxUnit.AREA} formatNumbers={false}/>
                </div>
            </div>
            <Button text={buttonText} onClick={onOpenFiltersButtonClick} mode={'white'}/>
        </div>
    );
};

export default FilterBar;
