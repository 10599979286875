import React, {useContext} from 'react';
import st from './apartment-card.module.scss'
import {TApartment} from "../../types/project";
import BenefitsArea from "../ui/benefits_area/benefits_area";
import {BULLED_POINT_STRING, formatNumber, RUBLE_SYMBOL, SQUARE_METER_STRING} from "../../utils/strings";
import {AppContext} from "../../context/context";
import Benefit from '../benefit/benefit';

const STUDIO_NAME = 'Студия';
const SECOND_OPTION_NAME_PART = '-комн.'
const BENEFITS_TYPES = {
    whitebox: ["whitebox"],
    withfinishing: [
        "finishing_economy",
        "finishingKitchen",
        "withfinishing",
        "modern_style_turnkey_furniture",
        "neoclassical_furniture",
        "modern_style",
        "neoclassical_style",
        "scandinavian_style",
        "aquilon",
        "bosphorus",
        "Bosphorus_ comfort",
        "gulfstream",
        "Gulfstream_comfort",
        "marshmallow",
        "finishing_comfort",
        "lamansh",
        "mistral",
        "sirocco",
        "Improved_finish",
        "with_furniture_appliances",
        "finishingKitchenFurniture",
        "finishingSlippers",
    ],
    withkitchen: [
        "finishingKitchen",
        "finishingKitchenFurniture",
        "finishingSlippers",
    ],
    withfurniture: [
        "finishingKitchenFurniture",
        "finishingSlippers",
    ]
}

interface IApartmentCardProps {
    apartment: TApartment;
}

const metro: any = {
    'Равновесие': {
        metroWalkTime: '14 мин.',
        metroName: 'м. Одинцово '
    }
}

const ApartmentCard = ({apartment}: IApartmentCardProps) => {

    const {setSelectedApartment, buildingList, floorList} = useContext(AppContext);

    const building = buildingList.filter((item: any) => item.id === apartment.buildingId)[0]
    const floor = floorList.filter((item: any) => item.id === apartment.buildingId)[0]
    apartment.floorsCount = building?.floorsCount || 0
    apartment.floorPlanImage = floor?.section?.[apartment.section]?.floor?.[apartment.floor]?.plan || ''
    apartment.masterPlanImage = building?.project_info.master_plan || ''
    apartment.buildingBody = building?.address || ''
    apartment.metroName = metro[apartment.projectName]?.metroName || ''
    apartment.metroWalkTime = metro[apartment.projectName]?.metroWalkTime || ''
    apartment.buildingNumber = building?.more.real_building_num || ''
    const getUnitedInfoString = () => {
        return `${apartment.rooms === STUDIO_NAME ? apartment.rooms : apartment.rooms + SECOND_OPTION_NAME_PART} `
            + `${BULLED_POINT_STRING} `
            + `${apartment.square + SQUARE_METER_STRING} `
            + `${BULLED_POINT_STRING} `
            + `${apartment.floor} этаж `
            + `${BULLED_POINT_STRING} `
            + `№${apartment.number}`
    }

    return (
        <button className={st.main}>
            <div className={st.title__container}>
                <span className={st.project_title}>{apartment.projectName}</span>
                <span className={st.metro_title}>{apartment.metroName}</span>
            </div>
            <div className={st.image__container} onClick={() => setSelectedApartment(apartment)}>
                {apartment.planImage === "https://feeds.kortros.ru/uploads/plans/perhushkovo_svg/.svg" ?
                <div className={st.img_placeholder}>
                    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="48" height="48" rx="24" fill="#FA1515"/>
                        <path d="M21.8418 16.7812V31H18.9121V16.7812H21.8418ZM30.3574 16.7812L24.7227 23.8027L21.4707 27.2988L20.9434 24.5254L23.1406 21.5176L26.7637 16.7812H30.3574ZM27.0273 31L22.8379 24.4277L25.0059 22.6113L30.4941 31H27.0273Z" fill="white"/>
                    </svg>
                </div> :
                <img className={st.img} src={apartment.planImage} alt={apartment.projectName} loading='lazy'/>
                }
            </div>
            <div className={st.regular__container + ' ' + st.benefits__container}>
                {apartment.price.before ? <Benefit isFilled={true}>Акция</Benefit> : null}
                {apartment.typicalFinishingType && BENEFITS_TYPES.whitebox.includes(apartment.typicalFinishingType) ? <Benefit>White Box</Benefit> : null}
                {apartment.typicalFinishingType && BENEFITS_TYPES.withfinishing.includes(apartment.typicalFinishingType) ? <Benefit>С отделкой</Benefit> : null}
                {apartment.typicalFinishingType && BENEFITS_TYPES.withkitchen.includes(apartment.typicalFinishingType) ? <Benefit>Кухня</Benefit> : null}
                {apartment.typicalFinishingType && BENEFITS_TYPES.withfurniture.includes(apartment.typicalFinishingType) ? <Benefit>Мебель</Benefit> : null}
            </div>
            <div className={st.regular__container}>
                <span className={st.united_info}>{getUnitedInfoString()}</span>
                <span
                    className={st.finish_project_info}>{`Сдача объекта: ${apartment.finishPlan.quarter} квартал ${apartment.finishPlan.year} года`}</span>
            </div>
            <div className={st.price__container}>
                <div className={st.price__inner_container}>
                    <span
                        className={st.actual_price}>{`${formatNumber(apartment.price.actually) + ' ' + RUBLE_SYMBOL}`}</span>
                    <span
                        className={st.last_price}>{`${formatNumber(apartment.price.before) + ' ' + RUBLE_SYMBOL}`}</span>
                </div>
            </div>
        </button>
    );
};

export default ApartmentCard;
