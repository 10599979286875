import React from 'react';

const Peculiarities = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.49612 8.06439L4.78258 18.9993H19.2256L20.512 8.06439L16.5022 10.7376L12.0041 4.44026L7.50597 10.7376L3.49612 8.06439ZM2.8048 5.19981L7.00408 7.99933L11.1903 2.13856C11.5113 1.68915 12.1359 1.58506 12.5853 1.90607C12.6751 1.97021 12.7536 2.04877 12.8178 2.13856L17.0041 7.99933L21.2033 5.19981C21.6629 4.89346 22.2837 5.01763 22.5901 5.47716C22.7218 5.67478 22.7789 5.91283 22.7512 6.1487L21.108 20.1162C21.0487 20.6198 20.6219 20.9993 20.1148 20.9993H3.89333C3.38625 20.9993 2.95943 20.6198 2.90018 20.1162L1.25695 6.1487C1.19242 5.6002 1.58476 5.10324 2.13326 5.03871C2.36914 5.01096 2.60719 5.06807 2.8048 5.19981ZM12.0041 14.9993C10.8995 14.9993 10.0041 14.1039 10.0041 12.9993C10.0041 11.8947 10.8995 10.9993 12.0041 10.9993C13.1086 10.9993 14.0041 11.8947 14.0041 12.9993C14.0041 14.1039 13.1086 14.9993 12.0041 14.9993Z"
                fill="#FA1515"/>
        </svg>
    );
};

export default Peculiarities;