import React from 'react';

const Floor = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M4 19V14H9V10H14V6H21V19" stroke="#FA1515" strokeWidth="2"/>
        </svg>
    );
};

export default Floor;