import {temporallyProjects} from "../temporally-source/projects";
import {TProject} from "../types/project";

export default class ProjectAPI {

    static async getProjectList():Promise<TProject[]> {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(temporallyProjects);
            }, 2000);
        });
    }

}
