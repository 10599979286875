import React from 'react';
import st from './tabs_area.module.scss';
import {c} from "../../../utils/classnames.utils";

interface ITabsAreaProps {
    options: string[];
    selectedOption: string;
    setSelectedOption: (selectedOption: string) => void;
}

const TabsArea = ({options, selectedOption, setSelectedOption}: ITabsAreaProps) => {

    // returns boolean to show or not show tab separator (grey vertical line)
    const isSeparatorVisible = (opt: string) => {
        const indexOfCurrentOpt = options.indexOf(opt);
        return opt !== selectedOption
            && (indexOfCurrentOpt < options.length - 1 && options[indexOfCurrentOpt + 1] !== selectedOption);
    }

    return (
        <div className={st.main}>
            {
                options.map((option, index) => (
                    <div key={index} className={c(st.tab__button, {[st.selected]: selectedOption === option})}
                         onClick={() => setSelectedOption(option)}>
                        <div className={c(st.tab__separator, {[st.visible]: isSeparatorVisible(option)})}>
                            {option}
                        </div>
                    </div>
                ))
            }
        </div>
    );
};

export default TabsArea;