export type TMinMaxValue = {
    minValue: {
        default: number;
        currency: number;
    },
    maxValue: {
        default: number;
        currency: number;
    }
}

export const getMinMaxValue = (min: number, max: number) => {
    return {
        minValue: {
            default: min,
            currency: min,
        },
        maxValue: {
            default: max,
            currency: max,
        }
    }
}

export enum EMinMaxUnit {
    PRICE = '₽',
    AREA = `м\u00B2`
}