import React from 'react';
import st from './benefits_area.module.scss';
import {c} from "../../../utils/classnames.utils";
import {EApartmentBenefitStyleType, TApartmentBenefit} from "../../../types/project";

interface IBenefitsAreaProps {
    benefitList: TApartmentBenefit[];
    fullWidth?: boolean;
}

const BenefitsArea = ({benefitList, fullWidth}: IBenefitsAreaProps) => {
    const getClassNames = (styleType: EApartmentBenefitStyleType) => {
        switch (styleType) {
            case EApartmentBenefitStyleType.RED:
                return c(st.benefit, st.red, {[st.full_width]:fullWidth});
            case EApartmentBenefitStyleType.WHITE:
                return c(st.benefit, st.white, {[st.full_width]:fullWidth});
        }
    }

    return (
        <div className={st.main}>
            {
                benefitList.map((benefit, index) =>
                    <div key={index} className={getClassNames(benefit.styleType)}>{benefit.title}</div>
                )
            }
        </div>
    );
};

export default BenefitsArea;