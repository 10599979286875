import React from 'react';

const Apartment = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.7577 11.8284L18.6066 3.97949L20.0208 5.3937L18.6066 6.80791L21.0815 9.28279L19.6673 10.697L17.1924 8.22213L15.7782 9.63634L17.8995 11.7577L16.4853 13.1719L14.364 11.0506L12.1719 13.2426C13.4581 15.184 13.246 17.8254 11.5355 19.5358C9.58291 21.4885 6.41709 21.4885 4.46447 19.5358C2.51184 17.5832 2.51184 14.4174 4.46447 12.4648C6.17493 10.7543 8.81633 10.5422 10.7577 11.8284ZM10.1213 18.1216C11.2929 16.95 11.2929 15.0506 10.1213 13.879C8.94975 12.7074 7.05025 12.7074 5.87868 13.879C4.70711 15.0506 4.70711 16.95 5.87868 18.1216C7.05025 19.2932 8.94975 19.2932 10.1213 18.1216Z"
                fill="#FA1515"/>
        </svg>
    );
};

export default Apartment;