export type TProject = {
    name: string;
    benefits: TProjectBenefit[];
    specials: TProjectBenefit[];
    finishingOption: TFinishingOption[];
    selectedBenefitIndex: number;
    apartments: TApartment[];
}

export type TProjectBenefit = {
    title: string;
    description: string;
    image: string;
}

export type TApartment = {
    id: string;
    address: string;
    buildingBody: string;
    number: string;
    projectName: string;
    planImage: string;
    floorPlanImage?: string;
    masterPlanImage?: string;
    metroName: string;
    metroWalkTime: string;
    floorsCount?: number;
    rooms: string;
    square: number;
    liveSquare?: number;
    finishing: string;
    section: string;
    floor: number;
    peculiarities?: string;
    typicalFinishingType?: string;
    buildingId?: string;
    buildingNumber?: string;
    finishPlan: {
        quarter: number;
        year: number;
    }
    benefits?: TApartmentBenefit[],
    specialOffers?: TApartmentSpecialOffer[],
    price: {
        actually: number;
        before: number;
    }
}

export type TFinishingOption = {
    id: number;
    title: string;
    items: TFinishingOptionItem[];
}

export type TFinishingOptionItem = {
    roomName: string;
    image: string;
}

export type TApartmentBenefit = {
    title: string,
    styleType: EApartmentBenefitStyleType,
}

export type TApartmentSpecialOffer = {
    title: string;
    body: string;
}

export enum EApartmentBenefitStyleType {
    RED,
    WHITE
}
