import React from 'react';

const Finishing = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5 4.99707V7.99707H19V4.99707H5ZM4 2.99707H20C20.5523 2.99707 21 3.44479 21 3.99707V8.99707C21 9.54936 20.5523 9.99707 20 9.99707H4C3.44772 9.99707 3 9.54936 3 8.99707V3.99707C3 3.44479 3.44772 2.99707 4 2.99707ZM6 11.9971H12C12.5523 11.9971 13 12.4448 13 12.9971V15.9971V21.9971H11V15.9971V13.9971H5C4.44772 13.9971 4 13.5494 4 12.9971V10.9971H6V11.9971ZM17.7322 13.7293L19.5 11.9616L21.2678 13.7293C22.2441 14.7056 22.2441 16.2886 21.2678 17.2649C20.2915 18.2412 18.7085 18.2412 17.7322 17.2649C16.7559 16.2886 16.7559 14.7056 17.7322 13.7293Z"
                fill="#FA1515"/>
        </svg>
    );
};

export default Finishing;