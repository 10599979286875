import React, {useMemo} from 'react';
import st from './project-carousel-benefit-button.module.scss';
import {TProjectBenefit} from "../../../types/project";
import {c} from "../../../utils/classnames.utils";

interface IProjectCarouselBenefitButtonProps {
    benefit: TProjectBenefit;
    benefitList: TProjectBenefit[];
    selectedBenefitIndex: number;
    setSelectedBenefitIndex: (index: number) => void;
}

const ProjectCarouselBenefitButton = ({
                                          benefit,
                                          benefitList,
                                          selectedBenefitIndex,
                                          setSelectedBenefitIndex
                                      }: IProjectCarouselBenefitButtonProps) => {

    const isSelected = useMemo(() => {
        return benefitList.indexOf(benefit) === selectedBenefitIndex;
    }, [benefitList, selectedBenefitIndex])

    return (
        <div className={st.main} onClick={() => setSelectedBenefitIndex(benefitList.indexOf(benefit))}>
            <div className={st.image__container}>
                <div className={c(st.selected_border__container, {[st.active]: isSelected})}>
                    <img className={st.img} src={benefit.image} alt={benefit.title}/>
                </div>
            </div>
            <div className={st.benefit_text}>{benefit.title}</div>
        </div>
    );
};

export default ProjectCarouselBenefitButton;