import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import {ApolloClient, ApolloProvider, InMemoryCache} from "@apollo/client";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
    uri: 'http://localhost:8010/proxy/graphiql?path=/graphql',
    cache: new InMemoryCache(),
});

root.render(
    <ApolloProvider client={client}>
        <App/>
    </ApolloProvider>,
);
