import React from 'react';
import st from './agreement-window.module.scss';
import {c} from "../../utils/classnames.utils";
import KortrosAvatar from "../../icons/kortros-avatar";
import OkIcon from "../../icons/ok-icon";
import {getAgreementText} from "../../utils/agreement-text";
import useTelegram from '../../hooks/useTelegram';

interface IConsentWindowProps {
    setUserHasAgreed: (value: boolean) => void;
}

const AgreementWindow = ({setUserHasAgreed}: IConsentWindowProps) => {
    const [conditionsAreOpen, setConditionsAreOpen] = React.useState(false);
    const [messagesReceiveAgreed, setMessagesReceiveAgreed] = React.useState(true);
    const {telegram} = useTelegram()
    return (
        <div className={st.main}>
            <div className={c(st.content__container, {[st.open]: conditionsAreOpen})}>
                {
                    !conditionsAreOpen ?
                        <>
                            <div className={st.info__container}>
                                <KortrosAvatar/>
                                <span className={st.title}>КОРТРОС</span>
                                <div className={st.link__container}>
                        <span className={st.text}>Открывая это мини-приложение, вы принимаете <span
                            className={c(st.text, st.link)} onClick={() => setConditionsAreOpen(true)}>условия использования мини-приложения</span></span>
                                </div>
                                <div className={st.check_box__container}
                                     onClick={() => setMessagesReceiveAgreed(prevState => !prevState)}>
                                    <div className={c(st.messages_agreed_button, {[st.active]: messagesReceiveAgreed})}>
                                        <OkIcon/>
                                    </div>
                                    <div className={st.messages_agreed_text__container}>
                              <span className={st.text}>Разрешить <span
                                  className={c(st.text, st.bold)}>КОРТРОС</span> писать мне сообщения</span>
                                    </div>
                                </div>
                            </div>
                            <div className={st.button__container}>
                                <div className={st.button} onClick={() => setUserHasAgreed(true)}>
                                    Запустить
                                </div>
                                <div className={st.button} onClick={() => telegram.close()}>
                                    Отмена
                                </div>
                            </div>
                        </> :
                        <>
                            <div className={st.text_area}>
                                <div className={st.text}>
                                    {getAgreementText()}
                                </div>
                            </div>
                            <div className={st.button__container}>
                                <div className={st.button} onClick={() => setConditionsAreOpen(false)}>
                                    Отмена
                                </div>
                            </div>
                        </>
                }
            </div>
        </div>
    );
};

export default AgreementWindow;
