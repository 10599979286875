import React, {useState} from 'react';
import st from './drop-down-components.module.scss';
import {c} from "../../../utils/classnames.utils";
import DropDownHandlerArrow from "../../../icons/drop-down-handler-arrow";
import {OPTIONS_LIST_ITEM_HEIGHT} from "./room-count-selector";

interface IDropDownListProps {
    selectedOption: string,
    setSelectedOption: (option: string) => void,
    optionList: string[]
}

const DropDownList = ({selectedOption, setSelectedOption, optionList}: IDropDownListProps) => {
    // drop down list with room count options is open or not
    const [optionListIsView, setOptionListIsView] = useState(false);

    // changes selected option and closes drop down list when user clicks to option.
    const buttonClick = (event: React.MouseEvent<HTMLDivElement>, opt: string) => {
        event.stopPropagation();
        setOptionListIsView(false);
        setSelectedOption(opt);
    }

    return (
        <div className={st.main} onClick={() => setOptionListIsView(prevState => !prevState)}>
            <div className={st.selected_option_area}>
                {selectedOption}
            </div>
            <div className={c(st.handler_area, {[st.open]: optionListIsView})}>
                <DropDownHandlerArrow/>
            </div>
            <div className={c(st.option_list__container, {[st.open]: optionListIsView})}
                 style={{height: optionListIsView ? `${optionList.length * OPTIONS_LIST_ITEM_HEIGHT}px` : 0}}>
                {
                    optionList.map(opt =>
                        <div key={opt}
                             className={c(st.option_list_item)}
                             onClick={(event) => buttonClick(event, opt)}>
                            {opt}
                        </div>
                    )
                }
            </div>
        </div>
    );
};

export default DropDownList;