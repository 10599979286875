import React, {MutableRefObject, useEffect, useMemo, useState} from 'react';
import st from './project-carousel-progress-line.module.scss';

interface IProjectCarouselProgressLineProps {
    allProjectCount: number;
    selectedProjectIndex: number;
}

const ProjectCarouselProgressLine = ({allProjectCount, selectedProjectIndex}: IProjectCarouselProgressLineProps) => {
    const backLineRef = React.useRef<HTMLDivElement>(null);
    const indicatorRef = React.useRef<HTMLDivElement>(null);

    // defines indicator width by projects count
    useEffect(() => {
        if (backLineRef.current && indicatorRef.current) {
            const indicatorWidthValue = Math.round((backLineRef as MutableRefObject<HTMLDivElement>).current.clientWidth / allProjectCount);
            (indicatorRef as MutableRefObject<HTMLDivElement>).current.style.width = indicatorWidthValue + 'px';
        }
    }, [backLineRef.current, indicatorRef.current]);

    // changes indicator position by selected project
    useEffect(() => {
        const newIndicatorPosition = (indicatorRef as MutableRefObject<HTMLDivElement>).current.clientWidth * selectedProjectIndex;
        (indicatorRef as MutableRefObject<HTMLDivElement>).current.style.left = `${newIndicatorPosition}px`;
    }, [selectedProjectIndex]);

    return (
        <div className={st.main} ref={backLineRef}>
            <div className={st.back_line}>
                <div ref={indicatorRef} className={st.indicator}/>
            </div>
        </div>
    );
};

export default ProjectCarouselProgressLine;