import {createContext} from "react";
import {TApartment, TProject} from "../types/project";
import { getDefaultOption, IReadyTermOption } from "../types/readyTermOptions";
import { getMinMaxValue, TMinMaxValue } from "../types/minMaxSlider";

export type TAppContextType = {
    isLoading: boolean;
    setIsLoading: (isLoading: boolean) => void;
    projects: TProject[];
    setProjects: (projects: TProject[]) => void;
    selectedProjectIndex: number;
    setSelectedProjectIndex: (index: number) => void;
    selectedApartment: TApartment | null;
    setSelectedApartment: (selectedObject: TApartment | null) => void;
    apartmentList: TApartment[];
    setApartmentList: (apartments: TApartment[]) => void;
    buildingList: any;
    setBuildingList: (buildings: any) => void;
    floorList: any;
    setFloorList: (floors: any) => void;
    user: any;
    setUser: (value: any) => void;
    maxRoomsCount: number;
    setMaxRoomsCount: (count: number) => void;
    readyTermOptions: IReadyTermOption[];
    setReadyTermOptions: (options: IReadyTermOption[]) => void;
    selectedRoomCountOptions: string[];
    setSelectedRoomCountOptions: (value: string[]) => void;
    minMaxPriceValues: TMinMaxValue;
    setMinMaxPriceValues: (value: TMinMaxValue) => void;
    minMaxAreaValues: TMinMaxValue;
    setMinMaxAreaValues: (value: TMinMaxValue) => void;
    readyTermSelectedOption: IReadyTermOption;
    setReadyTermSelectedOption: (value: IReadyTermOption) => void;
    phone: string;
    setPhone: (value: string) => void;
}

const defaultContext = {
    isLoading: false,
    setIsLoading: (isLoading: boolean) => console.log("Default callback"),
    projects: [],
    setProjects: (projects: TProject[]) => console.log("Default callback"),
    selectedProjectIndex: 0,
    setSelectedProjectIndex: (index: number) => console.log("Default callback"),
    selectedApartment: null,
    setSelectedApartment: (selectedObject: TApartment | null) => console.log("Default callback"),
    apartmentList: [],
    setApartmentList: (apartments: TApartment[]) => console.log("Default callback"),
    buildingList: [],
    setBuildingList: (buildings: any) => console.log("Default callback"),
    floorList: [],
    setFloorList: (floors: any) => console.log("Default callback"),
    user: null,
    setUser: (value: any) => console.log("Default callback"),
    maxRoomsCount: 0,
    setMaxRoomsCount: (count: number) => console.log("Default callback"),
    readyTermOptions: [],
    setReadyTermOptions: (options: IReadyTermOption[]) => console.log("Default callback"),
    readyTermSelectedOption: getDefaultOption(),
    setReadyTermSelectedOption: (value: IReadyTermOption) => console.log("Default callback"),
    minMaxPriceValues: getMinMaxValue(0, 0),
    setMinMaxPriceValues: (value: TMinMaxValue) => console.log("Default callback"),
    minMaxAreaValues: getMinMaxValue(0, 0),
    setMinMaxAreaValues: (value: TMinMaxValue) => console.log("Default callback"),
    selectedRoomCountOptions: [],
    setSelectedRoomCountOptions: (value: string[]) => console.log("Default callback"),
    phone: "",
    setPhone: (value: string) => console.log("Default callback"),
}

export const AppContext = createContext<TAppContextType>(defaultContext)
