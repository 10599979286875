import React from 'react';
import st from './characteristic-list.module.scss';
import {ICharacteristicItem} from "../../utils/lists/characteristic-list";

interface ICharacteristicListProps {
    list: ICharacteristicItem[];
}

const CharacteristicList = ({list}: ICharacteristicListProps) => {
    return (
        <div className={st.main}>
            {
                list.map(i =>
                    <div key={i.id} className={st.item}>
                        <div className={st.icon__container}>
                            {i.icon}
                        </div>
                        <div className={st.name_value_general__container}>
                            <div className={st.name__container}>
                                {i.name}
                            </div>
                            <div className={st.value__container}>
                                {i.value}
                            </div>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

export default CharacteristicList;