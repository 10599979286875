import React from 'react';
import st from './loader.module.scss';
import {c} from "../../../utils/classnames.utils";

interface ILoaderProps {
    isActive: boolean;
}

const Loader = ({isActive}: ILoaderProps) => {
    return (
        <div className={c(st.main, {[st.hide]: !isActive})}>
            <div className={st.loader}/>
        </div>
    );
};

export default Loader;