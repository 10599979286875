import React, {MutableRefObject, useEffect, useRef, useState} from 'react';
import {TImageGalleryItem} from "../../types/image-gallery";
import st from './image-gallery.module.scss';

interface IImageGalleryProps {
    items: TImageGalleryItem[];
}

const ImageGallery = ({items}: IImageGalleryProps) => {
    const [imagesIsLoading, setImagesIsLoading] = useState(false);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setImagesIsLoading(true);
        const loadImages = items.map(item =>
            new Promise((resolve, reject) => {
                const img = new Image();
                img.src = item.src;
                img.onload = () => resolve(item.src);
                img.onerror = () => reject(new Error(`Ошибка загрузки изображения: ${item.title}`));
            })
        );

        // Ждем завершения загрузки всех изображений
        Promise.all(loadImages)
            .catch(error => {
                console.error(error);
            })
            .finally(() => {
                setImagesIsLoading(false);
            });

        const scrollableArea = (scrollableAreaRef as MutableRefObject<HTMLDivElement>).current;
        scrollableArea.scrollTo({
            left: 0,
            behavior: 'smooth'
        });
    }, [items]);

    return (
        <div className={st.main}>
            <div className={st.scrollable} ref={scrollableAreaRef}>
                {
                    items.map((i, key) =>
                        <div key={key} className={st.item}>
                            <img src={i.src} className={st.img} alt={i.title ? i.title : 'Unknown image'}/>

                            {
                                i.title &&
                                <span className={st.title}>{i.title}</span>
                            }
                            <span className={st.description}>{i.description}</span>
                        </div>
                    )
                }
            </div>
            {
                imagesIsLoading &&
                <div className={st.loader__container}>
                    <div className={st.loader}/>
                </div>
            }
        </div>
    );
};

export default ImageGallery;