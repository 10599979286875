import style from './benefit.module.scss';

const Benefit = (
	{
		children = '',
		isFilled = false
	}
) => {
	return (
		<div className={style.benefit + ' ' + (isFilled ? style.filled : '')}>{children}</div>
	);
};

export default Benefit;
