import React, {useContext, useEffect, useRef} from 'react';
import st from './all-objects-page.module.scss'
import ProjectCarousel from "../../components/project-carousel/project-carousel";
import FilterBar from "../../components/filter-bar/filter-bar";
import ApartmentCard from "../../components/apartment-card/apartment-card";
import banner from '../../temporally-source/images/app-baner.png'
import {AppContext} from "../../context/context";
import {getMinMaxValue, TMinMaxValue} from "../../types/minMaxSlider";
import ApartmentAPI from "../../api/apartments";
import {getInitialDataFromRawData, TInitialData} from "../../types/initialData";
import {getDefaultOption, IReadyTermOption} from "../../types/readyTermOptions";
import {c} from "../../utils/classnames.utils";
import {mappingRawToApartment} from "../../utils/apartment-mapping/apartment-mapping";
import axios from 'axios';

const AllObjectsPage = () => {
    const {
        projects,
        setProjects,
        selectedProjectIndex,
        setSelectedProjectIndex,
        apartmentList,
        setApartmentList,
        setBuildingList,
        setFloorList,
        setMaxRoomsCount,
        maxRoomsCount,
        setReadyTermOptions,
        readyTermOptions,
        readyTermSelectedOption,
        setReadyTermSelectedOption,
        minMaxPriceValues,
        setMinMaxPriceValues,
        minMaxAreaValues,
        setMinMaxAreaValues,
        selectedRoomCountOptions,
        setSelectedRoomCountOptions
    } = useContext(AppContext);

    const [initialDataReceived, setInitialDataReceived] = React.useState<boolean>(false);

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    // filter options


    useEffect(() => {
        console.log('apartmentList', apartmentList)
        if(apartmentList.length > 0) {
            setIsLoading(false)
            setInitialDataReceived(true)
            return
        }
        getInitialData()
    }, []);

    const getInitialData = async () => {
        console.log('getInitialData')
        const allFlats = await ApartmentAPI.getAllApartments();
        const initialData: TInitialData | null = getInitialDataFromRawData(allFlats);

        console.log('initialData', initialData)
        setMaxRoomsCount(initialData.maxRoomCount);
        setReadyTermOptions(initialData.readyTermOptions);
        setMinMaxAreaValues(initialData.minMaxSquare);
        setMinMaxPriceValues(initialData.minMaxPrice);
        setInitialDataReceived(true);

        const uniqueBuildingIds = Array.from(new Set(allFlats.map((apartment: any) => apartment.building_id)));
        const buildings = await ApartmentAPI.getAllBuildings(uniqueBuildingIds);
        setBuildingList(buildings.map((building: any) => Object.values(building.data)[0]));
        const floors = await ApartmentAPI.getAllFloors(uniqueBuildingIds);
        setFloorList(floors.map((building: any) => Object.values(building.data)[0]))
    }

    useEffect(() => {
        if (initialDataReceived) {
            updateFlats();
        }
    }, [selectedRoomCountOptions, minMaxPriceValues, readyTermSelectedOption, minMaxAreaValues]);

    const updateFlats = async () => {
        setIsLoading(true);
        let res:any = null;
        try {
            const response:any = await ApartmentAPI.getApartmentsList(selectedRoomCountOptions, minMaxPriceValues, readyTermSelectedOption, minMaxAreaValues);
            res = response;
            if (response && response.status !== 500 && (!response.message || response.message !== 'Cancel')) {
                setApartmentList(mappingRawToApartment(response.data))
            }
        } finally {
            if (!res || (res.status !== 500 && res.message !== 'Cancel')) {
                setIsLoading(false);
            }
        }
    }


    return (
        <div className={st.main}>
            {
                projects.length > 0 &&
                <>
                    <div className={st.grey_side__container}>
                        <ProjectCarousel projects={projects} setProjects={setProjects}
                                         selectedProjectIndex={selectedProjectIndex}
                                         setSelectedProjectIndex={setSelectedProjectIndex}/>
                    </div>
                    <div className={st.white_side__container}>
                        <FilterBar
                            selectedRoomCountOptions={selectedRoomCountOptions}
                            minMaxPriceValues={minMaxPriceValues}
                            dropDownSelectedOption={readyTermSelectedOption}
                            minMaxAreaValues={minMaxAreaValues}
                            setSelectedRoomCountOptions={setSelectedRoomCountOptions}
                            setMinMaxPriceValues={setMinMaxPriceValues}
                            setDropDownSelectedOption={setReadyTermSelectedOption}
                            setMinMaxAreaValues={setMinMaxAreaValues}
                            dropDownOptionsList={readyTermOptions}
                            maxRoomsCount={maxRoomsCount}
                            isClickable={initialDataReceived}
                        />
                        <div className={st.apartments__container}>
                            {
                                apartmentList.length > 0 ?
                                    <>
                                        {
                                            apartmentList
                                                .slice(0, 3)
                                                .map((apartment, index) =>
                                                    <ApartmentCard key={index} apartment={apartment}/>
                                                )
                                        }
                                        <img src={banner} alt="banner" className="app_banner"/>
                                        {
                                            apartmentList.length > 3 &&
                                            <>
                                                {
                                                    apartmentList
                                                        .slice(2)
                                                        .map((apartment, index) =>
                                                            <ApartmentCard key={index} apartment={apartment}/>
                                                        )
                                                }
                                            </>
                                        }
                                    </> :
                                    <>
                                        {
                                            !isLoading &&
                                            <>
                                                <div className={st.not_found__message}>Ничего не найдено</div>
                                                <img src={banner} alt="banner" className="app_banner"/>
                                            </>
                                        }
                                    </>
                            }
                            <div className={c(st.apartments_loading__cover, {[st.active]: isLoading})}/>
                            <div className={c(st.apartments_loader__container, {[st.active]: isLoading})}>
                                <div className={st.loader}/>
                            </div>
                        </div>
                    </div>
                </>
            }
        </div>
    );
};

export default AllObjectsPage;
