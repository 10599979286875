import React from 'react';

const WalkingPersonIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.07874 5.80822L7.21545 4.25582C7.44999 4.0849 7.73979 3.99029 8.04399 4.00053C8.78492 4.01875 9.43559 4.50445 9.66192 5.21357C9.78612 5.60287 9.89939 5.86557 10.0018 6.00167C10.61 6.81033 11.5777 7.33333 12.6675 7.33333V8.66667C11.2177 8.66667 9.92232 8.00553 9.06639 6.9684L8.60132 9.60567L9.97539 10.7587L11.4575 14.8307L10.2046 15.2867L8.84465 11.5504L6.58449 9.65387C6.21946 9.3588 6.02158 8.87947 6.10886 8.38447L6.44793 6.46153L5.9967 6.7894L4.57869 8.74107L3.5 7.9574L5.06743 5.8L5.07874 5.80822ZM9.00079 3.66667C8.26439 3.66667 7.66745 3.06971 7.66745 2.33333C7.66745 1.59695 8.26439 1 9.00079 1C9.73712 1 10.3341 1.59695 10.3341 2.33333C10.3341 3.06971 9.73712 3.66667 9.00079 3.66667ZM7.01972 12.4542L4.87709 15.0077L3.8557 14.1507L5.83915 11.7869L6.33683 10.3333L7.53092 11.3333L7.01972 12.4542Z"
                fill="#F7A600"/>
        </svg>

    );
};

export default WalkingPersonIcon;