import axios from "axios";

const BACKEND_URL = 'https://api-kortros.pairmax.digital/telegram/estate/application'
export default class TelegramAPI {
	static async sendConsultation(guid: string, userId: string | number, project: string, phone: string) {
		const response = await axios.post(BACKEND_URL, JSON.stringify({
			guid: guid,
			userId: userId,
			project: project,
			phone: phone
		})).then((res) => res.data);
	return response;
	}
}
