import React from 'react';

const KortrosAvatar = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="24" fill="#FA1515"/>
            <path
                d="M21.8418 16.7812V31H18.9121V16.7812H21.8418ZM30.3574 16.7812L24.7227 23.8027L21.4707 27.2988L20.9434 24.5254L23.1406 21.5176L26.7637 16.7812H30.3574ZM27.0273 31L22.8379 24.4277L25.0059 22.6113L30.4941 31H27.0273Z"
                fill="white"/>
        </svg>
    );
};

export default KortrosAvatar;