import {getMinMaxValue, TMinMaxValue} from "./minMaxSlider";
import {getDefaultOption, IReadyTermOption} from "./readyTermOptions";

export type TInitialData = {
    maxRoomCount: number;
    readyTermOptions: IReadyTermOption[];
    minMaxPrice: TMinMaxValue;
    minMaxSquare: TMinMaxValue;
}

export const getInitialDataFromRawData = (rawData: any[]) => {
    let maxRoomCount = 0;
    const termOptions:IReadyTermOption[] = [getDefaultOption()];
    let minPrice = Number.MAX_VALUE;
    let maxPrice = 0;
    let minSquare = Number.MAX_VALUE;
    let maxSquare = 0;

    rawData.forEach((rawItem) => {
        // rooms count
        if (rawItem.room_count > maxRoomCount) {
            maxRoomCount = rawItem.room_count;
        }

        // term of build ready
        if (!termOptions.some(opt => opt.quarter === rawItem.readyQuarter && opt.year === rawItem.readyYear)) {
            termOptions.push({
                title: `${rawItem.readyQuarter} кв. ${rawItem.readyYear}`,
                queryValue: `${rawItem.readyQuarter}-${rawItem.readyYear}`,
                quarter: rawItem.readyQuarter,
                year: rawItem.readyYear,
            })
        }

        // price
        if (rawItem.discount < minPrice) {
            minPrice = rawItem.discount;
        }
        if (rawItem.discount > maxPrice) {
            maxPrice = rawItem.discount;
        }

        // square
        if (rawItem.square < minSquare) {
            minSquare = Math.floor(rawItem.square);
        }
        if (rawItem.square > maxSquare) {
            maxSquare = Math.floor(rawItem.square);
        }
    })

    return {
        maxRoomCount: maxRoomCount,
        readyTermOptions: termOptions.sort((rto1, rto2) => {
            if (rto1.year === rto2.year) {
                return rto1.quarter - rto2.quarter;
            }
            return rto1.year - rto2.year;
        }),
        minMaxPrice: getMinMaxValue(minPrice, maxPrice),
        minMaxSquare: getMinMaxValue(minSquare, maxSquare),
    };
}
