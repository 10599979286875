import React, {useEffect, useMemo, useState} from 'react';
import st from './finishing-options-viewer.module.scss';
import {TFinishingOption} from "../../types/project";
import DropDownList from "../ui/drop-down-components/drop-down-list";
import ImageGallery from "../image-gallery/image-gallery";
import {TImageGalleryItem} from "../../types/image-gallery";

interface IFinishingOptionsProps {
    options: TFinishingOption[]
}

const FinishingOptionsViewer = ({options}: IFinishingOptionsProps) => {
    const optionNames: string[] = useMemo(() => {
        return options.map(opt => opt.title);
    }, [options])

    const [selectedOptionName, setSelectedOptionName] = useState(optionNames.length > 0 ? optionNames[0] : '')

    const imageGalleryItems:TImageGalleryItem[] = useMemo(() => {
        const result:TImageGalleryItem[] = [];
        const selectedOption = options.find(opt => opt.title === selectedOptionName);
        if (selectedOption) {
            selectedOption.items.forEach(foi => {
                result.push({
                    src: foi.image,
                    description: foi.roomName,
                })
            })
        }
        return result;
    }, [selectedOptionName, options])

    return (
        <>
            {
                options.length > 0 &&
                <div className={st.main}>
                    <DropDownList selectedOption={selectedOptionName} setSelectedOption={setSelectedOptionName}
                                  optionList={optionNames}/>
                    <ImageGallery items={imageGalleryItems}/>
                </div>
            }
        </>
    );
};

export default FinishingOptionsViewer;