import React from 'react';

const LiveArea = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M4 3H20C20.5523 3 21 3.44772 21 4V20C21 20.5523 20.5523 21 20 21H4C3.44772 21 3 20.5523 3 20V4C3 3.44772 3.44772 3 4 3ZM5 5V12H9V14H5V19H19V12V5H13V10H11V5L5 5Z"
                  fill="#FA1515"/>
        </svg>
    );
};

export default LiveArea;