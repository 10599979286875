import axios, {CancelTokenSource} from "axios";
import {TMinMaxValue} from "../types/minMaxSlider";
import {IReadyTermOption} from "../types/readyTermOptions";

const BACKEND_URL = "https://web-app-kortros.pairmax.digital/api";
const cancelTokens: { [url: string]: CancelTokenSource } = {};

export default class ApartmentAPI {

    static async getApartmentsList(selectedRoomCountOptions:string[], minMaxPriceValues: TMinMaxValue, readyTermSelectedOption: IReadyTermOption, minMaxAreaValues: TMinMaxValue) {
        if (cancelTokens[BACKEND_URL]) {
            cancelTokens[BACKEND_URL].cancel('Запрос отменен из-за нового запроса.');
        }
        cancelTokens[BACKEND_URL] = axios.CancelToken.source();

        try {
            const response = await axios.get(BACKEND_URL, {
                params: {
                    rooms: stringsToQuery(selectedRoomCountOptions),
                    price: minMaxToQuery(minMaxPriceValues),
                    term: stringsToQuery([readyTermSelectedOption.queryValue]),
                    square: minMaxToQuery(minMaxAreaValues),
                    limit: 20,
                    page: 1,
                },
                cancelToken: cancelTokens[BACKEND_URL].token
            })
            return response;
        } catch (e) {
            if (axios.isCancel(e)) {
                return {status: 500, message: 'Cancel'};
            } else {
                console.error(`Ошибка запроса:`, e);
            }
        }
    }

    static async getAllApartments() {
        try {
            const response = await axios.get(BACKEND_URL, {
                params: {
                    limit: 20,
                    page: 1,
                }
            })
            return response.data;
        } catch (e) {
            console.error(`Ошибка запроса:`, e);
        }
    }

    static async getBuilding(id: number) {
        try {
            const response = await axios.get("https://api.kortros.tech/flats/perhushkovo/building", {
                params: {
                    building_id: id
                }
            })
            return response.data;

        } catch (e) {
            console.error(`Ошибка запроса:`, e);
        }
    }

    static async getAllBuildings(ids: any) {
        const responses = await Promise.all(ids.map(async (id: any) => {
            const response = await axios.get("https://api.kortros.tech/flats/perhushkovo/building/", {
                params: {
                    building_id: id
                }
            })
            return response.data;
        }))
        return responses
    }

    static async getAllFloors(ids: any) {
        const responses = await Promise.all(ids.map(async (id: any) => {
            const response = await axios.get("https://api.kortros.tech/flats/perhushkovo/floor/", {
                params: {
                    building_id: id
                }
            })
            return response.data;
        }))
        return responses
    }
}

const stringsToQuery = (strings: string[]) => {
    let result:string = '';
    for (let i = 0; i < strings.length; i++) {
        result += strings[i].replaceAll(' ', '')
        if (i < strings.length - 1) {
            result += ','
        }
    }
    return result;
}

const minMaxToQuery = (minMaxValue: TMinMaxValue) => {
    return `${minMaxValue.minValue.currency}-${minMaxValue.maxValue.currency}`;
}
