import {TProjectBenefit} from "./project";

export type TImageGalleryItem = {
    src: string,
    title?: string,
    description: string,
}

export const getImageGalleyItemsFromProjectBenefits = (benefits: TProjectBenefit[]):TImageGalleryItem[] => {
    const result:TImageGalleryItem[] = [];
    benefits.forEach((benefit) => {
        result.push({
            src: benefit.image,
            title: benefit.title,
            description: benefit.description,
        })
    })
    return result;
}