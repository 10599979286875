export const formatNumber = (num:number, spaces: boolean = true) => {
    if (spaces) {
        const numStr = num.toString().replace(/\D/g, '');
        return numStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    } else {
        return String(num);
    }
}

export const SQUARE_METER_STRING = `м\u00B2`;
export const BULLED_POINT_STRING = '•';
export const RUBLE_SYMBOL = '₽';
export const APPROXIMATELY_EQUAL = '≈';