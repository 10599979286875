import { useEffect } from 'react';

const useTelegram = () => {
  const telegram = window.Telegram?.WebApp;

  useEffect(() => {
    if (telegram) {
      telegram.expand(); // Разворачиваем мини-приложение
    }
  }, [telegram]);

  const getUserInfo = () => {
    return telegram?.initDataUnsafe?.user;
  };

  return {
      telegram,
      getUserInfo,
  };
};

export default useTelegram;
