import React from 'react';
import st from './button.module.scss';
import {c} from "../../../utils/classnames.utils";

interface IButtonProps {
    text: string;
    onClick: () => void;
    mode?: 'red' | 'white'
}

const Button = ({text, onClick, mode = 'red'}: IButtonProps) => {

    const getClassNames = () => {
        switch (mode) {
            case 'red':
                return st.main;
            case 'white':
                return c(st.main, st.white_mode);
        }
    }

    return (
        <div className={getClassNames()} onClick={() => onClick()}>
            {text}
        </div>
    );
};

export default Button;